// dateUtils.ts

/**
 * Counts the number of weekdays (Monday to Friday) between two dates inclusive.
 * @param startDate - The start date.
 * @param endDate - The end date.
 * @returns The number of weekdays between the two dates.
 */
export function countWeekdays(startDate: Date, endDate: Date): number {
    if (startDate > endDate) {
        return 0;
    }

    let weekdays = 0;
    let currentDate = new Date(Date.UTC(
        startDate.getUTCFullYear(),
        startDate.getUTCMonth(),
        startDate.getUTCDate()
    ));

    const endUTCDate = new Date(Date.UTC(
        endDate.getUTCFullYear(),
        endDate.getUTCMonth(),
        endDate.getUTCDate()
    ));

    while (currentDate <= endUTCDate) {
        const dayOfWeek = currentDate.getUTCDay();
        // Check if the current day is a weekday (Monday to Friday)
        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
            weekdays += 1;
        }
        // Move to the next day
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }

    return weekdays;
}

/**
 * Adds a specified number of days to a date without changing the time.
 * @param date - The original date.
 * @param daysToAdd - The number of days to add.
 * @returns A new Date object with the days added.
 */
export function addDaysToDate(date: Date, daysToAdd: number): Date {
    const newDate = new Date(Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate() + daysToAdd
    ));
    return newDate;
}

/**
 * Formats a Date object into a string 'YYYY-MM-DD' in UTC.
 * @param date - The Date object to format.
 * @returns A string representing the date in 'YYYY-MM-DD' format.
 */
export function formatDate(date: Date): string {
    const year = date.getUTCFullYear();
    const month = padZero(date.getUTCMonth() + 1); // Months are zero-based
    const day = padZero(date.getUTCDate());

    return `${year}-${month}-${day}`;
}

/**
 * Pads a number with a leading zero if it's less than 10.
 * @param num - The number to pad.
 * @returns A string with the number padded with a leading zero if necessary.
 */
function padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
}

/**
 * Determines the first working day of a given month.
 * 
 * @param year - The year for which the first working day is to be determined.
 * @param month - The month (0-based, where 0 = January and 11 = December) for which the first working day is to be determined.
 * @returns A Date object representing the first working day of the given month.
 */
function getFirstWorkDayOfMonth(year: number, month: number): Date {
    let date = new Date(year, month, 1); // First day of the month
    while (date.getDay() === 0 || date.getDay() === 6) { // 0 = Sunday, 6 = Saturday
        date.setDate(date.getDate() + 1);
    }
    return date;
}

/**
 * Checks if a given start date falls between the first calendar day of the month 
 * and the first working day of the same month (inclusive).
 * 
 * @param startDate - The date to check.
 * @returns A boolean indicating whether the start date falls in the range between 
 *          the first calendar day and the first working day of the month.
 */
export function isStartDateBetweenFirstAndFirstWorkDay(startDate: Date): boolean {
    const year = startDate.getFullYear();
    const month = startDate.getMonth();

    const firstDayOfMonth = new Date(year, month, 1);
    const firstWorkDayOfMonth = getFirstWorkDayOfMonth(year, month);

    // Reset time to midnight for accurate comparison
    firstDayOfMonth.setHours(0, 0, 0, 0);
    firstWorkDayOfMonth.setHours(0, 0, 0, 0);
    const startDateMidnight = new Date(startDate);
    startDateMidnight.setHours(0, 0, 0, 0);

    return startDateMidnight >= firstDayOfMonth && startDateMidnight <= firstWorkDayOfMonth;
}
