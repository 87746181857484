import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import {  Link, useRouteLoaderData, useLoaderData} from "react-router-dom";

import { Loader as Spinner} from "../components/loader";
import { BusinessDays, FormatDateToLocalString } from "../utils/formaters";
import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";

const xIcon = require("../img/icons/x.png");


export function ContractorNewInvoice(){

    const { invoices, paymentMethods }: any = useRouteLoaderData("dashboard");
    const { contracts }: any = useRouteLoaderData("contractor_root");

    const pending_invoices = invoices.invoices.filter((invoice: any)=> invoice.withdraw_method==null);
    
    const [selectedInvoice, setSelectedInvoice] = useState(pending_invoices[0]);

    const [loader, setLoader] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [bankAccount, setBankAccount] = useState(paymentMethods.length>0 ? paymentMethods[0].id : -1);

    async function submitForm(){
        setLoader(true);

        const data = {
            invoice_id: selectedInvoice.id,
            withdraw_method: bankAccount
        }
        const token = await LocalStorageManager.getToken();
        const newContract = await ComunaAPI.CreateInvoice(token, data);
    
        if(newContract){
            setShowConfirmation(true);
        }
        else{
            alert("There was an error creating the invoice, please try again or contact Support");
        }
        setLoader(false);
    }

    function Total(){
        return Math.round(selectedInvoice.salary_amount+selectedInvoice.benefits_amount+selectedInvoice.one_time_payments);
    }

    function UpdateSelectedInvoice(id:number){
        try {
            const selected = pending_invoices.filter((invoice: any)=> invoice.id===id)[0];
            setSelectedInvoice(selected);            
        } catch (error) {
            alert("There was an error selecting the invoice, please try again or contact Support")
        }

    }

    if(pending_invoices.length===0){
        alert("No invoice are available, contact support if you think this is an error");
        return null;
    }

    if(showConfirmation){
        return (createPortal(
            <div className="comuna-popup">
                <div className="comuna-card w-full relative fade-in py-6 pt-12 px-8" style={{maxWidth:524}}>
                <a
                    href={"/dashboard"}
                    className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
                    <img  src={xIcon} alt="icon of an x" />
                </a>
                <h1 className="black-text text-3xl text-center font-bold mb-6">Invoice Approved</h1>
                <p>Your invoice was approved, we will process it as soon as possible.</p>
                <a href={"/dashboard"} className="comuna-primary-button mt-6">Go back to dashboard</a>
                </div>
            </div>, 
        document.body))
    }

    return(createPortal(
        <div className="comuna-popup open">
            <div className="comuna-card w-full relative fade-in" style={{maxWidth:1024}}>
                <Link
                    to={"/dashboard"}
                    className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
                    <img  src={xIcon} alt="icon of an x" />
                </Link>

                <div className="py-6 pt-12 px-8">

                    <h1 className="black-text text-3xl text-center font-bold mb-6">Approve invoice</h1>
                    <p className="gray-text text-center" >Check it once, check it twice. This is how much you worked for the cycle?</p>
                    
                    <div className="bg-purple-light p-4 rounded-xl my-6">

                        <div className="flex flex-row w-full">

                            <div className="flex flex-col flex-1 mr-4">
                                <label className="comuna-input-label">Invoice</label>  
                                <select className="comuna-input" value={selectedInvoice.id} onChange={(e)=>{ UpdateSelectedInvoice(parseInt(e.target.value)) }}>
                                    {pending_invoices.length===0 && <option value="-1"> No Billing Dates Available </option>}
                                    {pending_invoices.map((item: any, index: number)=>{
                                        return <option key={index} value={item.id}>{FormatDateToLocalString(item.billed_to).split(",")[0]}</option>
                                    })}
                                </select>
                            </div>

                            <div className="flex flex-col mr-4" style={{width:124}}>
                                <label className="comuna-input-label">Days worked</label>
                                <span className="comuna-input">{selectedInvoice.days_worked}</span>
                            </div>


                            <div className="flex flex-col flex-1 ml-4">
                                <label className="comuna-input-label">Withdrawal method</label>  
                                <select 
                                    value={bankAccount}
                                    onChange={(e)=>{ setBankAccount(parseInt(e.target.value)) }}
                                    className="comuna-input">
                                    {paymentMethods.length === 0 && <option value="-1"> No Payment Methods Available </option>}
                                    {paymentMethods.map((method: any, index: number)=>{
                                        return <option key={index} value={method.id}>{method.bank_name+" - "+method.account_number}</option>
                                    })}
                                </select>
                            </div>

                        </div>

                        <div className="comuna-card p-4 mt-6">
                            
                            <div className="flex flex-row justify-between pb-2 mb-4" style={{borderBottom:"2px solid #EFEFEE"}}>
                                <span className="black-text">Description</span>
                                <span className="black-text">Amount</span>
                            </div>

                            <div className="flex flex-row justify-between pb-2">
                                <span className="black-text">Salary</span>
                                <span className="font-medium comuna-purple">${Math.round(selectedInvoice.salary_amount).toLocaleString("en-US")}</span>
                            </div>

                            {selectedInvoice.benefits_amount>0 && <div className="flex flex-row justify-between pb-2">
                                <span className="black-text">Benefits</span>
                                <span className="font-medium comuna-purple">${Math.round(selectedInvoice.benefits_amount).toLocaleString("en-US")}</span>
                            </div>}

                            {selectedInvoice.one_time_payments>0 && <div className="flex flex-row justify-between pb-2">
                                <span className="green-text">Bonus Payments</span>
                                <span className="green-text font-medium">${Math.round(selectedInvoice.one_time_payments).toLocaleString("en-US")}</span>
                            </div>}

                        </div>

                    </div>

                </div>

                <div className="bg-purple-light p-6 flex flex-row">
                    <div className="flex-1">
                        <span className="text-black">Total</span>
                        <span className="ml-4 text-3xl font-bold gray-text">$<span className="comuna-purple">{Total().toLocaleString("en-US")}</span> </span>
                    </div>
                    <Link to={"/dashboard"}className="comuna-secondary-button sm mr-4">Cancel</Link>
                    <button className="comuna-primary-button sm" onClick={submitForm}>
                        {loader ? <Spinner/> : "Approve the invoice"}
                    </button>
                </div>

            </div>
        </div>
    , document.body))
}