import { useContext, useEffect, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { strings } from "../strings";
import { UserContext } from "../Providers/UserContext";
import { RoleDescriptionView } from "./RoleDescriptionView";
import { copyToClipBoard } from "../utils/clipboard";
import { formatStringDateFromServer } from "../utils/formaters";

interface ContractorProposalReviewProps {
	proposal: any;
	currency_code: string;
	currency_symbol: string;
}

const ContractorProposalReview: React.FC<ContractorProposalReviewProps> = ({
	proposal, currency_code, currency_symbol
}) => {

	const { token, user }: any = useContext(UserContext);

	const [currentPage, setCurrentPage] = useState<string>("detail");
	const [copied, setCopied] = useState(false);

	const backIcon = require("../img/backIcon.png");
	const paperIconNoBG = require("../img/copyIcon.png");

	const [showFullDescription, setFullDescription] = useState(false);

	const [formattedDate, setFormattedDate] = useState<string>("");

	const showFullDescriptionHandler = () => {
		setFullDescription(!showFullDescription);
	};

	const description = showFullDescription
		? proposal.role_description
		: proposal.role_description.slice(0, 20) + "...";

	useEffect(() => {
		console.log("proposal");
		console.log(proposal);
		console.log("user: ");
		console.log(user);
		console.log("can sign contract: ");
		console.log(proposal.can_sign_contract);
		setFormattedDate(formatStringDateFromServer(proposal.start_date));
	}, [proposal]);

	return (
		<div className="h-fit w-full p-5">
			{proposal !== undefined &&
				proposal.state === 1 &&
				!proposal.can_sign_contract && (
					<div className="flex flex-col h-fit rounded-xl bg-comuna-light">
						<label className="text-md font-bold comuna-purple text-left">
							Proposal Accepted
						</label>
						<label className="text-sm comuna-purple text-left">
							{strings.proposal_standby_msg}
						</label>
					</div>
				)}

			{proposal !== undefined && proposal.state > 1 && (
				<div
					className="w-full h-full mb-2" // BUG: flowbite/tailwind colors, rounded and padding dont seem to work as expected here
					// style={{
					// 	borderRadius: "10px",
					// 	padding: "10px",
					// 	backgroundColor: "comuna-red-light",
					// }}
				>
					<div className="flex flex-col bg-comuna-red-light rounded-xl p-2">
						<label className="text-sm font-extrabold comuna-red text-left">
							Proposal Rejected
						</label>
						<label className="text-sm comuna-red text-left">
							{strings.proposal_contractor_rejection_msg}
						</label>
					</div>
				</div>
			)}

			{/** Contract role description */}
			{currentPage === "description" ? (
				<div className="w-full h-full">
					<RoleDescriptionView
						selectedContractor={{
							city_location: user.city_location,
							country_location: user.country_location,
							cv: user.cv,
							date_of_birth: user.date_of_birth,
							email: user.email,
							email_verified: user.email_verified,
							entity_name: user.entity_name,
							entity_type: user.entity_type,
							first_name: user.first_name,
							last_name: user.last_name,
							linkedin: user.linkedin,
							mailing_address: user.mailing_address,
							mailing_address_detail: user.mailing_address_detail,
							name: user.name,
							passport_id_number: user.passport_id_number,
							phone_country_indicator: user.phone_country_indicator,
							phone_number: user.phone_number,
							phone_verified: user.phone_verified,
							preferred_name: user.preferred_name,
							profile_picture: user.profile_picture,
							profile_type: user.profile_type,
							registered_as: user.registered_as,
							role_name: user.role_name,
							salary_expectation: user.salary_expectation,
							skills: user.skills,
							title: user.title,

							vat_id: user.vat_id,
							website: user.website,
							zip_code: user.zip_code,
							role_description: proposal.role_description,
						}}
						setPage={setCurrentPage}
					/>
				</div>
			) : (
				<div className="flex w-full h-full">
					<div className="flex flex-col h-full w-full text-gray-700 dark:text-white">
						<div className="flex flex-col w-full h-fit">
							<div className="leftBlock flex flex-col w-full h-fit">
								<div className="flex flex-col w-full h-fit justify-between bg-white rounded-md pb-2">
									<label className="comuna-input-label">
										Contact information
									</label>
									<div className="flex flex-row w-full h-fit comuna-sub-card-light py-1 px-2 items-center">
										<label className="w-full comuna-input-label pl-2 pb-1">
											Client Email
										</label>
										<div className="flex flex-row w-full h-fit p-1 items-center text-center">
											<label className="w-full text-sm font-medium text-black">
												{copied
													? "copied"
													: proposal.company_profile.client_email}
											</label>
											{window.location.protocol === "https:" && (
												<img
													src={paperIconNoBG}
													className="h-4 w-4"
													onClick={() => {
														copyToClipBoard(
															proposal.company_profile.client_email
														);
														setCopied(true);
														window.setTimeout(() => {
															setCopied(false);
														}, 2000);

														// var textField = document.createElement("textarea");
														// textField.innerText =
														// 	proposal.complany_profile.client_email;
														// document.body.appendChild(textField);
														// textField.select();
														// document.execCommand("copy");
														// textField.remove();
													}}
												/>
											)}
										</div>
									</div>
								</div>
								<div className="flex flex-col w-full h-fit mt-2">
									<label className="comuna-field">Contract</label>
									<div className="flex flex-row w-full">
										<div className="flex flex-row w-full h-fit items-center comuna-sub-card-light mr-3 p-1">
											<label className="w-full comuna-input-label pb-2 px-2 ">
												Length
											</label>
											<label className="w-full text-xs font-bold text-right pr-3">
												1 year
											</label>
										</div>
										<div className="flex flex-row w-full h-fit items-center comuna-sub-card-light p-1">
											<label className="comuna-input-label pb-2 px-2 w-full">
												Hours
											</label>
											<label className="text-xs font-bold w-full h-full py-2 text-right pr-3">
												160 / month
											</label>
										</div>
									</div>
									<div className="flex flex-row w-full mt-3">
										<div className="flex flex-row w-full h-fit items-center comuna-sub-card-light mr-3 p-1">
											<label className="comuna-input-label pb-2 px-2 w-full">
												Start date
											</label>
											<label className="text-xs font-bold w-full h-full py-2 text-right pr-3">
												{formattedDate}
												{/* {proposal.start_date} */}
											</label>
										</div>
										<div className="flex flex-row w-full h-fit items-center comuna-sub-card-light p-1">
											<label className="comuna-input-label pb-2 px-2 w-full">
												End date
											</label>
											<label className="text-xs font-bold w-full h-full py-2 text-right pr-3">
												{proposal.end_date}
											</label>
										</div>
									</div>
									<div className="flex flex-row w-full mt-3">
										<div className="flex flex-row w-full h-fit items-center comuna-sub-card-light mr-3 p-1">
											<label className="w-full h-full comuna-input-label px-2 py-1">
												Payment cadence
											</label>
											<label className="w-full h-full text-xs font-bold capitalize py-1 text-right px-3">
												{proposal.payment_cadence}
											</label>
										</div>
										<div className="flex flex-row w-full h-fit items-center comuna-sub-card-light p-1">
											<label className="w-full h-full comuna-input-label px-2 py-1">
												Candidate Salary
											</label>

											<label className="text-xs py-1 w-full h-full text-right text-black font-bold pr-3">
												{currency_symbol +" " + proposal.yearly_salary + " " + currency_code + " "}
												/ year
											</label>
										</div>
									</div>
								</div>

								{proposal.benefits.length > 0 && (
									<>
										<div className="w-full border border-b border-b-grey-300 mt-4" />
										<div className="flex flex-col w-full mt-2">
											<label className="comuna-label-input my-2">
												Benefits
											</label>
											<div className="flex flex-row h-fit w-full flex flex-row flex-wrap">
												{proposal.benefits.map((benefit: any, i: number) => {
													return (
														<div
															className="benefit-badge tooltip"
															style={{ marginRight: 8 }}>
															{benefit.name}
															<div
																className="tooltiptext"
																style={{ minWidth: 312 }}>
																{benefit.description}
															</div>
														</div>
													);
												})}
											</div>
										</div>
									</>
								)}

								<div className="w-full border border-b border-b-grey-300 mt-4" />
								<div className="flex flex-col w-full h-full mt-2">
									<label className="comuna-input-label font-bold">
										Role description
									</label>
									<div
										dangerouslySetInnerHTML={{ __html: description }}
										className="w-full comuna-input-label pb-2 "></div>
									<button
										className="self-end comuna-purple underline"
										onClick={showFullDescriptionHandler}>
										Read {showFullDescription ? "Less" : "More"}
									</button>
								</div>
								{/* proposal.role_description, */}
							</div>
							{/* </div> */}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ContractorProposalReview;
