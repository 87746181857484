import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useRouteLoaderData, useLoaderData, useParams, Link} from "react-router-dom"

import { formatDateFromString } from "../utils/formaters";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";

import  {Loader as Spinner} from "../components/loader";
import { BenefitItem } from "../components/BenefitItem";
import { ContractorProfilePicture } from "../components/ContractorProfilePicture";
import {BenefitsForm} from "../components/ResourceRequest/BenefitsForm_v1.1";

const chevron = require("../img/icons/chevron-dark-up.png");
const infoIcon = require("../img/icons/info-circle.png");
const plusIconPurple = require("../img/icons/plus-purple.png");
const xIcon = require("../img/icons/x.png");


export async function  Loader(){
    const token = await LocalStorageManager.getToken();
    const pendingChanges = await ComunaAPI.getUpdatesForContractorContracts(token);
    return {
        pendingChanges
    };
}

export function ClientMyTeamEdit(){
    
    const { pendingChanges }: any = useLoaderData();
    const { team_data, benefit_categories, available_benefits, organizationDetails }: any = useRouteLoaderData("team");

    const { contract_id } = useParams();

    const [loading, setLoading] = useState<boolean>();
    const [currentContractor, setCurrentContractor] = useState<any>();
    const [pendingChange, setPendingChange] = useState<boolean>(false);
    const [showBenefitForm, setShowBenefitForm] = useState<boolean>(false);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    useEffect(() => {
        //get selected contractor by filtrating team_data with the contract_id
        const selectedContractor = team_data.filter(
            (contractor: any) => contractor.contract_id === contract_id
        )[0];
        setCurrentContractor(selectedContractor);

        //check if there are pending changes for the selected contractor
        pendingChanges.forEach((change: any) => {
            if (change.contractor_contract.id.toString() === contract_id && !change.applied) {
                var newContractor = { ...selectedContractor };
                newContractor.payment_cadence = change.payment_cadence;
                newContractor.salary = change.salary;
                newContractor.benefits = change.benefits;
                newContractor.role_name = change.contractor_contract.role_name;
                newContractor.role_description = change.contractor_contract.role_description;
                setCurrentContractor(newContractor);
                setPendingChange(true);
            }
        });
    }, [pendingChanges]);


    function UpdateContractor(field: string, value: any) {
        if(field === "benefits"){
            //iterate throught the IDs and update the value on the contractor array
            const newBenefits:any[] = [];
            value.forEach((benefitId:any)=>{
                available_benefits.forEach((benefit:any)=>{
                    if(benefit.id === benefitId){
                        newBenefits.push(benefit);
                    }
                });
            });
            value = newBenefits;
        }

        var newContractor = { ...currentContractor };
		newContractor[field] = value;
		setCurrentContractor(newContractor);
	}

	function deleteBenefit(benefitId: any) {
        //delete it from the contractor object
		const updatedArray = currentContractor.benefits.filter((benefit: any) => {
			return benefit.id !== benefitId;
		});

        const newContractor = { ...currentContractor };
        newContractor.benefits = updatedArray;
        setCurrentContractor(newContractor);
	}

	function GetUpdateDate() {
		var date = new Date();
		//update the current date to the first day of the next month
		date.setDate(1);
		date.setMonth(date.getMonth() + 1);
		return formatDateFromString(date.toISOString());
	}

    function GetBenefitsIds(){
        var ids:any[] = [];
        currentContractor.benefits.forEach((benefit:any)=>{
            ids.push(benefit.id);
        });
        return ids;
    }



    async function SubmitForm(){

        if (loading) return;
        if (currentContractor.role_description.length > 2048) {
            alert( "Role description is too long, please shorten it to 2048 characters or less");
            return;
        }
            
        setLoading(true);
        const token = await LocalStorageManager.getToken();
        var noError = true;
        try {
			setLoading(true);
			await ComunaAPI.UpdateContractorContract(
				token,
				currentContractor.contract_id,
				{
					payment_cadence: currentContractor.payment_cadence,
					salary: currentContractor.salary,
					benefits: currentContractor.benefits.map((benef: any) => {
						return benef.id;
					}),
					title: currentContractor.role_name,
					role_description: currentContractor.role_description,
					role: currentContractor.role_name,
				}
			);
		} catch (error) {
			noError = false;
			alert("Error updating contract, please try again");
		} finally {
			if (noError) {
                await window.location.reload();
			}
			await setLoading(false);
			window.location.reload();
		}

    }

    if(!currentContractor || currentContractor.role_description == null){
        return(<Spinner />);
    } 

    return(
        <div className="flex flex-col w-full h-full py-12 px-6" style={{overflowY:"auto"}}>
            <div style={{maxWidth:1024}}>
			<div className="flex flex-row w-full mb-8 items-center">
				<Link
                    to={"/team/"+contract_id}
					>
					<img style={{ 
                            width: 20,
                            transform: "rotate(-90deg)",
                            marginRight: 10
                     }} src={chevron} alt="" />
				</Link>
				<h4 className="text-3xl font-extrabold text-black text-left">
					Edit Contract
				</h4>
			</div>


            {pendingChange ? (
				<div className="bg-c p-4 rounded-xl mx-4 mb-6 bg-light-red">
					<p className="color-red">
						This contract has pending changes that will be applied at{" "}
						<b> {"  " + GetUpdateDate()}</b>
					</p>
				</div>
			) : (
				<div className="skill-badge rounded-xl mx-4 mb-6 comuna-purple">
                    <div className="p-4">
                    <div className="flex flex-row mb-2">
                        <img src={infoIcon} alt="" style={{width: 24, marginRight:10}} />
                        <b>
						Any changes to the contract details will only apply on{" "}
						{"  " + GetUpdateDate()} and if the Contractor approves them first.
					    </b>
                    </div>
					<span>
						Changes to the contract are always only applied in the following
						month.
					</span>
                    </div>
				</div>
			)}

            <div className="bg-white rounded-xl p-6 h-fit" >
                <ContractorProfilePicture contractor={currentContractor} />
                
                <div className="mt-6">

                {/** Role Name */}
				<label className="comuna-input-label">Role Name</label>
				<input
					className="comuna-input"
					type="text"
					value={currentContractor.role_name}
					onChange={(e) => {
                        UpdateContractor("role_name", e.target.value);
					}}
                    />
                
                {/** Role Description */}
				<div className="flex flex-row justify-between w-full">
					<label className="comuna-input-label">
						Role Description
					</label>
					<label className={"comuna-input-label " +(currentContractor.role_description.length < 2049 ? "" : "text-red-500")}>
						{currentContractor.role_description.length + "/2048"}
					</label>
				</div>

				<textarea
					className="comuna-input"
					value={currentContractor.role_description}
					name="role-description"
					id="role_description"
					onChange={(e) => {
						UpdateContractor("role_description", e.target.value);
					}}
					cols={30}
					rows={12}></textarea>

                {/** Payment Cadence and  Salary */}
                <div className="flex flex-row">
					<div className="flex flex-col w-full mr-4">
						<label className="w-full text-sm my-1 mt-4 color-gray-5 font-medium">
							Payment cadence
						</label>
						<select
							className="comuna-input"
							value={currentContractor.payment_cadence}
							name="payment_cadence"
							id="payment_cadence"
							onChange={(e) => {
								UpdateContractor("payment_cadence", e.target.value);
							}}>
							<option value="monthly">Monthly</option>
							<option value="bimonthly">Twice a Month</option>
						</select>
					</div>
					<div className="flex flex-col w-full">
						<label className="w-full text-sm my-1 mt-4 color-gray-5 font-medium">
							Candidate salary
						</label>
						<div className="comuna-input">
							<span>{organizationDetails.currency.symbol}</span>
							<input
								onChange={(e) => {
									UpdateContractor("salary", e.target.value);
								}}
								type="number"
								value={currentContractor.salary}
							/>
							<span>{` ${organizationDetails.currency.code} / year`}</span>
						</div>
					</div>
				</div>

                <p className="comuna-input-label" style={{marginBottom:20}}>
                *if you edit the salary to a lower price you’ll have to start negotiation with the candidate
                </p>

                <label className="w-full text-md mt-2 mt-4 black-text font-medium">
					Benefits
				</label>
				<div className="flex flex-row h-fit w-full rounded-md mt-2 flex-wrap items-start">
					{currentContractor.benefits.map((benef: any, i: number) => {
						return (
							<BenefitItem
								key={i}
								benefit={benef}
								onDelete={deleteBenefit}
								big={true}
								canDelete={true}
							/>
						);
					})}

					<button
						className="comuna-purple flex flex-row items-center rounded-3xl px-4 py-2 border-comuna-purple border-2"
						onClick={() => {
							setShowBenefitForm(true);
						}}>
						<img
							src={plusIconPurple}
							alt="plus icon purple"
							style={{ width: 20, marginRight: 7 }}
						/>
						<span className="text-lg">Add more</span>
					</button>
				</div>
                
                
                <button className="comuna-primary-button sm mt-12" onClick={()=>{ setShowConfirmation(true); }}>
					{loading?<Spinner/>:"Save changes"}
                </button>

                </div>
            </div>
            </div>

            {showBenefitForm && 
                <div className="comuna-popup open">
                    <div className="comuna-card fade-in p-8" style={{maxWidth:1024}}>
                        {/** Close Button */}
                        <button
                            onClick={()=>{ setShowBenefitForm(false) }}
                            className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
                                <img src={xIcon} alt="icon of an x" />
                        </button>
                        <BenefitsForm 
                        benefits={GetBenefitsIds()}
                        setBenefits={(b:any[])=> { UpdateContractor("benefits", b); } }
                        availableBenefits={available_benefits} 
                        categories={benefit_categories}
                        />
                    </div>
                </div>}
				

			{showConfirmation &&
				createPortal(
					<div className="comuna-popup open">
						<div className="comuna-card flex flex-col text-center justify-center w-6/12 self-center relative px-8 pb-12 fade-in" style={{maxWidth:524}}>
							<button
								onClick={()=>{ setShowConfirmation(false) }}
								style={{ top: 16, right: 16, position: "absolute" }}>
								<img src={xIcon} alt="close icon" style={{ width: 19 }} />
							</button>
							<label className="text-2xl font-bold mt-14 w-full self-center">
								Request changes to contractor?
							</label>
							<label className="w-full text-md text-black mt-8">
								Request contract changes to the contract with{" "}
								<label className="comuna-purple font-semibold">
									{currentContractor.name}
								</label>
								?
							</label>
							<label className="text-md text-black mt-6 w-full self-center">
								If you proceed, we’ll send {" " + currentContractor.name + " "}{" "}
								a notification so they can review and approve the changes. After the approval, the changes apply from:
							</label>

							<div className="w-full rounded-lg bg-comuna-purple-transparent self-center my-4 p-2">
								<label className="text-2xl comuna-purple font-bold self-center">
									{"" + GetUpdateDate()}
								</label>
							</div>

							<button
									className="comuna-primary-button my-4"
									onClick={() => {
										SubmitForm();
									}}>
										{loading?<Spinner/>:"Request changes"}
							</button>
							
							<button
									className="comuna-secondary-button mr-4"
									onClick={() => {
										setShowConfirmation(false);
									}}>
									Cancel
							</button>
							

						</div>
					</div>,
					document.body
				)}

        </div>
    )
}