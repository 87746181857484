import React, { useContext, useEffect, useState } from "react";
import { Outlet, NavLink, useLoaderData } from "react-router-dom";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";
import { RoleOneTimePayment } from "../components/RoleOneTimePayment";
import { SetFinishDateForContract } from "../components/SetFinishDateForContract";

const chevronIcon = require("../img/icons/chevron-dark-up.png");

export async function Loader() {
	const token = await LocalStorageManager.getToken();
	const team_data = await ComunaAPI.GetMyTeam(token);
	const userResReqReturn = await ComunaAPI.getResReqFields(token);
	const organizationDetails = await ComunaAPI.GetOrganizationProfile(token);

	return {
		team_data,
		benefit_categories: userResReqReturn.benefits_categories,
		available_benefits: userResReqReturn.benefits,
		organizationDetails
	};
}

export const ClientMyTeam: React.FC = () => {
	const { user, setBusy }: any = useContext(UserContext);

	const { team_data, organizationDetails }: any = useLoaderData();

	const [selectedContractor, setSelectedContractor] = useState<any>();
	const [showCompleted, setShowLeftMembers] = useState<boolean>(false);

	const [finishDatePopup, setFinishDatePopup] = useState<boolean>(false);
	const [oneTimePopup, setOneTimePopup] = useState<boolean>(false);

	useEffect(() => {
		//rerouting logic according to role
		if (user.role === "interviewer") {
			window.location.href = "/hiring";
		}
		if (
			(window.location.pathname === "/team" ||
				window.location.pathname === "/team/") &&
			team_data.length > 0
		) {
			window.location.href = "/team/" + team_data[0].contract_id;
			setSelectedContractor(team_data[0]);
		}

		setBusy(false);
	}, []);

	interface ContractorLinkProps {
		role: any;
	}

	function ContractorLink({ role }: ContractorLinkProps) {
		const regular =
			"flex flex-row w-full h-fit items-center px-3 py-3 rounded-2xl bg-purple-light mt-2 hover-right";
		const selected =
			"flex flex-row w-full h-fit items-center px-3 py-3 rounded-2xl bg-purple-dark mt-2 hover-right";

		return (
			<NavLink
				to={"/team/" + role.contract_id}
				onClick={() => {
					setSelectedContractor(role);
				}}
				className={({ isActive }) => (isActive ? selected : regular)}>
				{role.profile_picture ? (
					<img
						style={{
							width: 56,
							height: 56,
							borderRadius: "100%",
						}}
						loading="lazy"
						className="cursor-pointer"
						src={role.profile_picture}
						alt="face of the contractor"
					/>
				) : (
					<b
						style={{
							width: 56,
							height: 56,
						}}
						className="h-14 text-2xl rounded-full bg-white comuna-purple flex justify-center items-center">
						{role.first_name[0].toUpperCase() + role.last_name[0].toUpperCase()}
					</b>
				)}
				<b className="left-0 text-sm color-black px-2">{role.name}</b>
			</NavLink>
		);
	}

	const toggleLeftMembers = () => setShowLeftMembers(!showCompleted);

	const isCompleted = (role: any) => ["terminated", "finished"].includes(role.state);
	const completedCount = team_data.filter(isCompleted).length; // Count left members

	return (
		<div className="bg-purple-light w-full min-heigh-full flex flex-row">
			<div className="comuna-sidebar flex flex-col">
				<div className="w-full h-full px-6 py-2">
					{team_data && team_data.length > 0 ? (
						<>
							{team_data.map((role: any) => (
								!isCompleted(role) && <ContractorLink key={role.contract_id} role={role} />
							))}
							{completedCount > 0 && <>
								<div className="my-2 flex flex-row justify-between items-center">
									<span>{showCompleted ? `Hide completed contracts (${completedCount})` : `Show completed contracts (${completedCount})`}</span>
									<button className="hover-scale" onClick={toggleLeftMembers}>
										<img
											src={chevronIcon}
											alt="toggle arrow"
											className="transition"
											style={{
												transform: showCompleted ? "scale(1,-1)" : "",
												width: "auto",
												height: 10,
											}}
										/>
									</button>
								</div>
								{showCompleted && (
									<div className="flex flex-col overflow-hidden transition">
										{team_data.filter(isCompleted).map((role: any) => (
											<ContractorLink key={role.contract_id} role={role} />
										))}
									</div>
								)}
							</>}
						</>
					) : (
						<div className="flex flex-col">
							<div className="placeholder-block"></div>
							<div className="placeholder-block"></div>
							<div className="placeholder-block"></div>
						</div>
					)}
				</div>
			</div>

			<Outlet />

			{/** Finish date popup */}
			{finishDatePopup && (
				<SetFinishDateForContract
					contractId={selectedContractor.contract_id}
					contractName={selectedContractor.name}
					onClose={() => {
						setFinishDatePopup(false);
					}}
				/>
			)}

			{/** One Time Popup */}
			{oneTimePopup && (
				<RoleOneTimePayment
					update={() => {
						/* this would call an Update my team info page */
					}}
					onClose={() => {
						setOneTimePopup(false);
					}}
					selectedContractor={selectedContractor}
					currency_code={organizationDetails.currency.code}
					currency_symbol={organizationDetails.currency.symbol}
				/>
			)}
		</div>
	);
};
