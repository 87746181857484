import { Button } from "flowbite-react";
import { useState } from "react";
import { createPortal } from "react-dom";
import { useRouteLoaderData, Link, useParams } from "react-router-dom";

const xIcon = require("../img/icons/x.png");

export function ContractorDetailInvoice() {

    const { invoice_id }: any = useParams();
    const {invoices, paymentMethods}:any = useRouteLoaderData("dashboard");

    const invoice = invoices.invoices.find((invoice:any)=>invoice.id===parseInt(invoice_id));
    const maxDays = invoice.payment_cadence==="monthly" ? 20:10;
    const withdrawMethod = paymentMethods.find((method:any)=>method.id===invoice.withdraw_method);

    function Total(){
        return invoice.salary_amount+invoice.benefits_amount+invoice.one_time_payments;
    }

    return (
        createPortal(
        <div className="comuna-popup open">
            <div className="comuna-card w-full relative fade-in" style={{maxWidth:1024}}>
                <button
                    onClick={()=>{window.history.back()}}
                    className="absolute top-4 right-4 font-bold h-7 w-7 text-center cursor-pointer text-2xl color-gray-5">
                    <img  src={xIcon} alt="icon of an x" />
                </button>

                <div className="py-6 pt-12 px-8">
                    <h1 className="black-text text-3xl text-center font-bold mb-6">Invoice #{invoice.id} Details</h1>
                    <div className="bg-purple-light p-4 rounded-xl my-6">
                        <div className="flex flex-row w-full">

                            <div className="flex flex-col flex-1 mr-4">
                                <label className="comuna-input-label">Invoice</label>
                                <span className="comuna-input">{invoice.billed_from} - {invoice.billed_to}</span>
                            </div>

                            <div className="flex flex-col mr-4" style={{width:124}}>
                                <label className="comuna-input-label">Days worked</label>  
                                <p className="comuna-input">{invoice.days_worked}</p>
                            </div>

                            <div className="flex flex-col" style={{width:124}}>
                                <label className="comuna-input-label">PTO or sick days</label>  
                                <p className="comuna-input">{maxDays - invoice.days_worked}</p>
                            </div>

                            <div className="flex flex-col flex-1 ml-4">
                                <label className="comuna-input-label">Withdrawal method</label>  
                                <p className="comuna-input whitespace-nowrap">{withdrawMethod.bank_name+" - "+withdrawMethod.account_number}</p>
                            </div>

                        </div>
                    
                        <div className="comuna-card p-4 mt-6">
                            
                            <div className="flex flex-row justify-between pb-2 mb-4" style={{borderBottom:"2px solid #EFEFEE"}}>
                                <span className="black-text">Description</span>
                                <span className="black-text">Amount</span>
                            </div>

                            <div className="flex flex-row justify-between pb-2">
                                <span className="black-text">Salary</span>
                                <span className="font-medium comuna-purple">{"$" + invoice.salary_amount.toLocaleString("en-US")}</span>
                            </div>

                            {invoice.benefits_amount>0 && <div className="flex flex-row justify-between pb-2">
                                <span className="black-text">Benefits</span>
                                <span className="font-medium comuna-purple">{"$" + invoice.benefits_amount.toLocaleString("en-US")}</span>
                            </div>}

                            {invoice.one_time_payments>0 && <div className="flex flex-row justify-between pb-2">
                                <span className="green-text">Bonus Payments</span>
                                <span className="green-text font-medium">{"$" + invoice.one_time_payments.toLocaleString("en-US")}</span>
                            </div>}

                        </div>

                    </div>
                </div>

                <div className="bg-purple-light p-6 flex flex-row">
                    <div className="flex-1">
                        <span className="text-black">Total</span>
                        <span className="ml-4 text-3xl font-bold gray-text">{"$ "} <span className="comuna-purple">{Total().toLocaleString("en-US")}</span> </span>
                    </div>
                    <button  onClick={()=>{window.history.back()}} className="comuna-primary-button sm mr-4">Go Back</button>
                </div>
            </div>
        </div>, document.body))
}